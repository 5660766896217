export const ServicesApave = [
    {
        id: 1,
        code: "ApogéeOne",
        label: "ApogéeOne",
        url: "https://pp-apogee.oneapave.com/APOGEE_PPBin/MOS_XML.dll/IALogin"
    },
    {
        id: 2,
        code: "MAINTASaaS-V12-PROD",
        label: "MAINTA GMAO",
        url: "https://apogee-dev.cloud-serveur.fr/"
    },
    {
        id: 3,
        code: "MAINTASaaS-V12-TEST",
        label: "MAINTA GMAO",
        url: "https://apogee-dev.cloud-serveur.fr/"
    },
    {
        id: 4,
        code: "MAINTASaaS-V12-PROTO",
        label: "MAINTA GMAO",
        url: "https://apogee-dev.cloud-serveur.fr/"
    },
    {
        id: 5,
        label: "AviséOne",
        code: "AVISE-STANDARD",
        url: "https://apogee-dev.cloud-serveur.fr/AVISE_PRODBin/MOS_XML.dll"
    },
    {
        id: 8,
        label: "FPEOne",
        code: "FPE-STANDARD",
        url: "https://apogee-dev.cloud-serveur.fr/"
    },
    {
        id: 9,
        label: "Grand Portail",
        code: "GRAND-PORTAIL",
        url: "https://pp.apave.com/"
    },
    {
        id: 1008,
        label: "ApogéeOne",
        code: "ApogéeOne New",
        url: "https://new-apogee.oneapave.com/APOGEE_PRODBin/MOS_XML.dll"
    },
];