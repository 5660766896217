import { useEffect, useState } from "react";
import { canPerform } from "shared/services/Can";
import CommentItemComponent from "./CommentItemComponent";
import FieldComponent from 'shared/components/FieldComponent';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import _ from "lodash";

export const ACTION_CREATE = 'create';
export const ACTION_DELETE = 'delete';
export const ACTION_UPDATE = 'update';

export default function CommentsComponent(props) {

    const { value, onChange, actions, onAction, errors, watchsite } = props;
    const [newComment, setNewComment] = useState(false);
    const [account] = useAccount();
    const [canWrite] = useAccess();

    function addAction(action, id = null) {
        let newActions = [...actions];
        const index = actions.findIndex(cAction => cAction.id === id);
        if (!(action === ACTION_UPDATE && id === null)) {
            if (index > -1) {
                newActions = actions.filter((item, cIndex) => cIndex !== index);
            }
            if (!(action === ACTION_DELETE && id === null)) {
                newActions.push({action: action, id: id});
            }
        }
        _.delay(() => onAction(newActions), 100);
    }

    function addComment(event) {
        event.preventDefault();
        onChange([...value, {
            id: null,
            comment: "",
            created_at: new Date(),
        }]);
        setNewComment(true);   
        addAction(ACTION_CREATE);
    }

    function updateComment(id, field, v) {
        let newComments = [...value];
        const index = newComments.findIndex(comment => comment.id === id);
        newComments[index][field] = v;
        onChange(newComments);
        addAction(ACTION_UPDATE, id);
    }

    function removeComment(id) {
        const index = value.findIndex(comment => comment.id === id);
        const newComments = value.filter((item, cIndex) => cIndex !== index);
        onChange(newComments);

        if (newComment && id === null) {
            setNewComment(false);
        }
        addAction(ACTION_DELETE, id);
    }

    useEffect(() => {
        // Show again "Add a new comment" button after submiting new comment
        for(let v in value) {
            if (value[v].id === null) {
                setNewComment(true);
                return;
            }
        }
        setNewComment(false);
    }, [value])

    const comments = _.orderBy(value, ['created_at'], ['desc']);

    return (<>
        <div style={{maxHeight: 400, overflow: 'auto'}}>
            <table className="table">
                <tbody>
                    {comments.length > 0 && comments.map(comment => {
                        if (comment.id) {
                            return (<CommentItemComponent
                                key={comment.id}
                                value={comment}
                                onChange={updateComment}
                                onRemove={removeComment}
                                error={errors[comment.id]}
                                watchsite={watchsite}
                            />);
                        }
                        return null;
                    })}
                    {(comments.length === 0 || (comments.length === 1 && newComment)) && <tr><td colSpan="3">Aucun commentaire.</td></tr>}
                </tbody>
            </table>
        </div>
        {canWrite(watchsite.id) && canPerform('sheetaffected:comment:create', {account: account}) && !newComment && (
            <div className="border-t bg-gris-10 border-gris-25 ">
                <div className="text-right">
                    <button onClick={addComment} className="btn btn-primary icon uppercase">
                        <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                        &nbsp;Ajouter un commentaire
                    </button>
                </div>
            </div>
        )}
        {canWrite(watchsite.id) && canPerform('sheetaffected:comment:create', {account: account}) && newComment && (
            <table className="table">
                <tbody>
                    {comments.length > 0 && comments.map(comment => {
                        if (comment.id === null) {
                            return (
                                <tr key={"new-comment"} className={"border-b border-gris-25 bg-gris-10"}>
                                    <td className="w-200px text-right">
                                        <span>Rajouter un commentaire</span>
                                    </td>
                                    <td>
                                        <FieldComponent
                                            type="textarea"
                                            className="field h-100px"
                                            name={"comment[" + comment.id + "]"}
                                            value={comment.comment}
                                            onChange={v => updateComment(comment.id, "comment", v)}
                                            wrap={false}
                                            rte={true}
                                            error={errors[-1] ? errors[-1].comment : null}
                                        />
                                    </td>
                                    <td className="border-l border-gris-25 w-40px no-padding valign-center">
                                        <ul className="actions">
                                            <li>
                                                <button onClick={() => removeComment(null)} className="infobulle" data-info="Supprimer le commentaire">
                                                    <i className="icon-boutons-poubelle" aria-hidden="true"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            );
                        }
                        return null;
                    })}
                </tbody>
            </table>
        )}
    </>);
}