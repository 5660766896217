import AbstractApi from 'shared/api/Abstract';

const CategoryApi = {

    /* Categories and subcategories */
    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("categories/searches", query, from, size, sorting, direction);
    },

    save: function(data) {
        return AbstractApi.save("categories", data);
    },

    get: function(id) {
        return AbstractApi.get("categories", id);
    },

    delete: function(id) {
        return AbstractApi.delete("categories", id);
    }
}

export default CategoryApi;