import React from 'react';

export default function Footer(props) {

    return (
        <footer className="footer" title={props.screen ? `Ecran ${props.screen}` : null}>
            <p>Apave - Pilot Veille 1.0</p>
        </footer>	
    )
}


Footer.defaultProps = {
    screen: ""
};
