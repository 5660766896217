import _ from 'lodash';

export function useAccess() {
    let writableWatchistes = [];
    let readableWatchsites = [];

    if (!_.isUndefined(localStorage.getItem("writable-watchsites"))) {
        writableWatchistes = JSON.parse(localStorage.getItem("writable-watchsites"));
    }
    if (!_.isUndefined(localStorage.getItem("readable-watchsites"))) {
        readableWatchsites = JSON.parse(localStorage.getItem("readable-watchsites"));
    }

    function canWrite(watchsite) {
        return writableWatchistes.includes(watchsite);
    }

    function canRead(watchsite) {
        return readableWatchsites.includes(watchsite) || canWrite(watchsite);
    }

    function setWritableWatchsites(data) {
        writableWatchistes = data;
        localStorage.setItem("writable-watchsites", JSON.stringify(writableWatchistes));
    }

    function setReadableWatchsites(data) {
        readableWatchsites = data;
        localStorage.setItem("readable-watchsites", JSON.stringify(readableWatchsites));
    }

    return [canWrite, canRead, writableWatchistes, readableWatchsites, setWritableWatchsites, setReadableWatchsites];
}