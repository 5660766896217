import React from 'react';
import _ from 'lodash';
import StatusComponent from 'shared/components/StatusComponent';
import RequirementAffectedItemList from 'views/requirementaffected/List/ItemListAffectation';
import SectionTree from 'views/requirementaffected/List/SectionTree';
import { getApplicabilityColor, getApplicabilityLabel } from 'data/ApplicabilityState';
import { stripTags } from 'shared/services/Utils';

export default function RequirementItemList(props) {

    const { errors, item, toggled, toggleRequirement, selectedAffectations, selected, onSelect, onAffectationSelect, onAffectationChange, pilotsForWatchsites } = props;
    const affectations = item.data;
    const affectationAmount = affectations ? affectations.length : 0;

    if (typeof item.data == "string") {
        item.data = JSON.parse(item.data.replaceAll('\\n', ''));
    }

    return (<>
        <tr id={"requirement-" + item.id}>
            <td className="no-padding w-34px text-center valign-top" rowSpan={(affectationAmount === 1 || (affectationAmount > 1 && toggled)) ? 2 : null}>
                <input
                    checked={selected}
                    onChange={(event) => onSelect(item, event.target.checked)}
                    type="checkbox"
                    className="select-requirement"
                    id={"select-requirement-" + item.id}
                />
                <label htmlFor={"select-requirement-" + item.id} style={{margin: 10}}></label>

                {affectationAmount > 1 && (
                    <div className="bg-vert-apave">
                        <button 
                            className={"toggler color-blanc" + (toggled ? " actif" : "")}
                            type="button"
                            onClick={() => toggleRequirement(item.id)}
                        >
                            {affectationAmount}
                        </button>
                    </div>
                )}
            </td>
            <td className="text-center">{item.id}</td>
            <td>{item.requirement.section && <SectionTree sections={item.requirement.section} />}</td>
            <td><span title={item.requirement.description}>{stripTags(_.truncate(item.requirement.description, {'length': 255}))}</span></td>
            <td>{item.requirement.article}</td>
            <td>
                <StatusComponent 
                    border
                    value={getApplicabilityLabel(item.requirement.applicability_state)}
                    color={getApplicabilityColor(item.requirement.applicability_state)}
                />
            </td>
        </tr>
        {(affectationAmount === 1 || (affectationAmount > 1 && toggled)) && (
            <tr>
                <td colSpan={5} style={{padding: 0}}>
                    <table className="table table-borderless">
                        <tbody>
                            {affectations.map((affectation)  => 
                                <RequirementAffectedItemList
                                    key={affectation.id}
                                    item={affectation}
                                    selected={selectedAffectations.includes(affectation.id)}
                                    onSelect={onAffectationSelect}
                                    onChange={onAffectationChange}
                                    affectationAmount={affectationAmount}
                                    requirement={item}
                                    errors={errors[affectation.id] || []}
                                    pilotsForWatchsites={pilotsForWatchsites}
                                />
                            )}
                        </tbody>
                    </table>
                </td>
            </tr>
        )}
    </>);
}