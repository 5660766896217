import AbstractApi from 'shared/api/Abstract';

const PREFIX = "watchsitesaffected";

const WatchsiteAffectedApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search(PREFIX + "/searches", query, from, size, sorting, direction);
    },
}
export default WatchsiteAffectedApi;