import { React, Link } from 'commons';
import { FileListComponent } from "shared/components";
import { formatDate } from "shared/services/Utils";
import { prefixLinkTo } from 'shared/services';

export default function DocumentarySpaceDetailRow(props) {

    let elt = '';
    let value = props.value;

    if (props.type) {
        switch (props.type) {
            case 'date':
                value = formatDate(props.value);
                break;
            case 'file':
                value = <FileListComponent values={value} style={{padding: 0}} />
                break;
            case 'link':
                value = <Link to={prefixLinkTo() + props.to}>{value}</Link>;
                break;
            case 'link-external':
                value = <Link to={prefixLinkTo() + {pathname: value}} target="_blank" rel="noreferrer">{props.labelLink ? props.labelLink : value}</Link>;
                break;
            case 'text':
                value = <>{value.split('\n').map((item, key) => {
                    return <React.Fragment key={key}>{item}<br/></React.Fragment>
                })}</>;
                break;
            case 'rte':
                value = <><div dangerouslySetInnerHTML={{__html: value}} /></>;
                break;
            default:
                break;
        }
    }

    if (elt !== '') {
        return elt;
    } else {
        if (props.children) {
            return <>
                <div className="flex-label mb-0">
                    <label>{props.label} : </label>
                    <div>{value}</div>
                </div>
                <div className="flex-label"><label></label>{props.children}</div>
            </>;
        } else {
            return <div className="flex-label">
                <label>{props.label} : </label>
                <div>{value}</div>
            </div>;
        }
    }
}
