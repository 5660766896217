import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { prefixLinkTo } from 'shared/services';
import TreeWatchsiteComponent from 'views/tree/tree/TreeWatchsiteComponent';

export default function TreeNodeComponent(props) {

    const [active, setActive] = useState(true);

    return (
        <li id={`node-${props.item.id}`} className={active === true ? "actif" : ""}>
            {(props.item.childs.length > 0 || props.item.watchsites.length > 0) && 
                <button type="button" className="btn btn-primary icon icon-only" onClick={() => active === true ? setActive(false) : setActive(true)}>
                    <i className={active ? "icon-boutons-moins" : "icon-boutons-plus"}></i>
                </button>
            }
            <Link id={`watchsite-${props.item.id}-link`} to={prefixLinkTo() + `/tree/nodes/${props.item.id}`}>
                {props.item.label}
            </Link>
            <ul id={`node-${props.item.id}-childs`} className={active === true ? "" : "d-none"}>
                {props.item.childs.map((child) => (
                    <TreeNodeComponent key={child.id} item={child}  />
                ))}
                {props.item.watchsites.map((watchsite) => (
                    <TreeWatchsiteComponent key={watchsite.id} item={watchsite} />
                ))}
            </ul>            
        </li>
    )
}