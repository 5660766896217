import AbstractApi from 'shared/api/Abstract';

const PREFIX = "statistics";

const StatisticsApi = {

    search: function(query, from, newLimit, newSorting, newDirection, additionnalParams) {
        return AbstractApi.search(
            PREFIX + "/searches/" + additionnalParams.axis + "/" + additionnalParams.indicator,
            query,
            from,
            newLimit,
            newSorting,
            newDirection
        );
    },

    export: function(exportType, axis, indicator, query) {
        return AbstractApi.export(PREFIX, query, exportType + "/" + axis + "/" + indicator);
    }
}
export default StatisticsApi;