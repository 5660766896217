import { React } from 'commons';
import { denyIfCantPerform } from 'shared/services';
import ObligationGeneratorForm from 'shared/views/obligationgenerator/Form';
import { useAccount } from 'hooks/Account';

export default function ObligationGeneratorFormWrapper(props) {

    const [account] = useAccount();
    denyIfCantPerform(props, "obligationgenerator:read", {account: account});
    return <ObligationGeneratorForm />;
}