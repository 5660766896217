export const PRIORITY_NONE = 0;
export const PRIORITY_1 = 1;
export const PRIORITY_2 = 2;
export const PRIORITY_3 = 3;
export const PRIORITY_4 = 4;

export const PRIORITY_LABEL = {
    [PRIORITY_NONE]: "Aucune",
    [PRIORITY_1]: "P1",
    [PRIORITY_2]: "P2",
    [PRIORITY_3]: "P3",
    [PRIORITY_4]: "P4",
}

export const PRIORITY_COLOR = {
    [PRIORITY_NONE]: "valid",
    [PRIORITY_1]: "valid",
    [PRIORITY_2]: "valid",
    [PRIORITY_3]: "valid",
    [PRIORITY_4]: "valid",
}

export function getPriorities() {
    let priorities = [];
    for (const [key, value] of Object.entries(PRIORITY_LABEL)) {
        priorities.push({
            value: parseInt(key), 
            label: value,
            color: PRIORITY_COLOR[key],
        });
    }
    return priorities;
}

export function getPriorityLabel(p) {
    return PRIORITY_LABEL[p];
}