export const CONFORMITY_NEW = 0;
export const CONFORMITY_INFO = 1;
export const CONFORMITY_NA = 2;
export const CONFORMITY_TOSPECIFIED = 3;
export const CONFORMITY_NOK = 4;
export const CONFORMITY_OK = 5;

export const CONFORMITY_MODE_GLOBAL = 0;
export const CONFORMITY_MODE_REQUIREMENT = 1;

export const STATUS_TODECIDE = 0; // affectation unfinish : after base duplication
export const STATUS_TODO = 1; // affectation unfinish : after deferred assignment
export const STATUS_TOSENT = 2; // affectation unfinish : manual status for consultant
export const STATUS_SENT_BY_APAVE = 3; // affectation OK
export const STATUS_ADD_BY_CUSTOMER = 4; // affectation OK
export const STATUS_ARCHIVED = 5; // affectation NOK

export const SHEET_AFFECTED_CONFORMITY = {
    [CONFORMITY_NEW]: "Nouveau",
    [CONFORMITY_INFO]: "Information",
    [CONFORMITY_NA]: "Non applicable",
    [CONFORMITY_TOSPECIFIED]: "A préciser",
    [CONFORMITY_NOK]: "En écart",
    [CONFORMITY_OK]: "Conforme",
}

export const SHEET_AFFECTED_CONFORMITY_COLOR = {
    [CONFORMITY_NEW]: "new",
    [CONFORMITY_INFO]: "gris-25",
    [CONFORMITY_NA]: "na",
    [CONFORMITY_TOSPECIFIED]: "alert",
    [CONFORMITY_NOK]: "error",
    [CONFORMITY_OK]: "valid",
}

export const SHEET_AFFECTED_CONFORMITY_MODE = {
    [CONFORMITY_MODE_GLOBAL]: "Global",
    [CONFORMITY_MODE_REQUIREMENT]: "Par exigence",
}

export const SHEET_AFFECTED_CONFORMITY_MODE_SHORT = {
    [CONFORMITY_MODE_GLOBAL]: "G",
    [CONFORMITY_MODE_REQUIREMENT]: "E",
}

export const AFFECTATION_STATUS = {
    [STATUS_TODECIDE]: "A statuer (après duplication)",
    [STATUS_TODO]: "A faire (envoi différé)",
    [STATUS_TOSENT]: "A envoyer (gestion manuel des consultants)",
    [STATUS_SENT_BY_APAVE]: "Affectée par Apave",
    [STATUS_ADD_BY_CUSTOMER]: "Ajoutée par le client",
    [STATUS_ARCHIVED]: "Archiver",
}

export function getConformities() {
    let status = [];

    let options = [
        CONFORMITY_NEW,
        CONFORMITY_TOSPECIFIED,
        CONFORMITY_NOK,
        CONFORMITY_OK,
        CONFORMITY_INFO,
        CONFORMITY_NA,
    ];

    for (const value of options) {
        status.push({
            value: value, 
            label: SHEET_AFFECTED_CONFORMITY[value],
            color: SHEET_AFFECTED_CONFORMITY_COLOR[value],
        });
    }
    return status;
};

export function getConformityLabel(conformity) {
    return SHEET_AFFECTED_CONFORMITY[conformity];
}

export function getConformityColor(conformity) {
    return SHEET_AFFECTED_CONFORMITY_COLOR[conformity];
}

export function getConformityModes(hasRequirement = true, short = false) {
    let conformityModes = [];
    for (const [key, value] of Object.entries(short ? SHEET_AFFECTED_CONFORMITY_MODE_SHORT : SHEET_AFFECTED_CONFORMITY_MODE)) {
        if (parseInt(key) === CONFORMITY_MODE_REQUIREMENT && hasRequirement === false) {
            continue;
        }
        conformityModes.push({
            value: parseInt(key), 
            label: value,
            color: "valid",
        });
    }
    return conformityModes;
};

export function getConformityModeLabel(mode, short = false) {
    if (short) {
        return SHEET_AFFECTED_CONFORMITY_MODE_SHORT[mode];
    } else {
        return SHEET_AFFECTED_CONFORMITY_MODE[mode];
    }
}

export function getAffectationStatusLabel(status) {
    return AFFECTATION_STATUS[status];
}