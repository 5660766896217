import { useState } from "react";

export default function ToggableTextComponent(props) {

    const [toggled, setToggled] = useState(true);
    
    const text = props.children || "";
    const limit = props.limit || 50;

    if (text.length > limit) {
        return (
            <p>
                {toggled ? text.substring(0, limit) : text}
                {toggled && <span className="toggle-group"><em>...</em></span>}
                &nbsp;<button 
                    onClick={() => setToggled(!toggled)}
                    className={"toggler color-vert-apave" + (toggled ? "" : " actif")}
                    type="button"
                ></button>
            </p>
        );
    }
    
    return <p>{text}</p>;
}