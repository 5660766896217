export const APPLICABILITY_INCOMING = 0;
export const APPLICABILITY_CURRENT = 1;
export const APPLICABILITY_EXPIRED = 2;

export const APPLICABILITY_STATUS_COLOR = {
    [APPLICABILITY_INCOMING]: "warning",
    [APPLICABILITY_CURRENT]: "valid",
    [APPLICABILITY_EXPIRED]: "error",
}

export const APPLICABILITY_LABEL = {
    [APPLICABILITY_CURRENT]: "Actuelle",
    [APPLICABILITY_INCOMING]: "A venir",
    [APPLICABILITY_EXPIRED]: "Caduque",
}

export function getApplicabilityColor(applicability) {
    return APPLICABILITY_STATUS_COLOR[applicability];
}

export function getApplicabilityLabel(applicability) {
    return APPLICABILITY_LABEL[applicability];
}

export function getApplicabilities() {
    let applicabilities = [];
    for (const [key, value] of Object.entries(APPLICABILITY_LABEL)) {
        applicabilities.push({
            value: parseInt(key), 
            label: value,
            color: APPLICABILITY_STATUS_COLOR[key],
        });
    }
    return applicabilities;
}