export default function ConformityStatisticsChartLegend() {

    return (
        <>
            <ul className="progress-legend">
                <li><span className="bg-conformity-new"></span>Nouveau</li>
                <li><span className="bg-conformity-tospecified"></span>A préciser</li>
                <li><span className="bg-conformity-nok"></span>En écart</li>
                <li><span className="bg-conformity-ok"></span>Conforme</li>
            </ul>
        </>
    );
}