import { React, Link } from 'commons';
import { prefixLinkTo } from 'shared/services';
import DocumentarySpaceResultRowPicto from 'views/documentaryspace/Result/Row/Picto';
import DocumentarySpaceDetailForm from 'views/documentaryspace/Detail/Form';

export default function DocumentarySpaceResultRowForm(props) {

    function openDetail(event) {
        event.preventDefault();
        props.setDetail(<DocumentarySpaceDetailForm onRequestClose={() => props.setDetail(null)} document={props.row} />);
    }

    return <li>
        <DocumentarySpaceResultRowPicto row={props.row} />
        <div className="contenu">
            <div className="infos">
                <Link onClick={openDetail} to={prefixLinkTo() + `/documentary-space/form/${props.row.id}`}>
                    {props.row.number && <>{props.row.number} - </>}
                    {props.row.title}
                </Link>
            </div>
        </div>
    </li>;
}