import { React } from 'commons';

export default function DocumentarySpaceResultRowPicto(props) {

    return <div className="statuts">
        {props.row.date_end_novelty && new Date(props.row.date_end_novelty) > new Date() && <span className="statut bg-error uppercase">Nouveau</span>}
        {
            !(props.row.date_end_novelty && new Date(props.row.date_end_novelty) > new Date())
            && props.row.date_end_update_notification 
            && new Date(props.row.date_end_update_notification) > new Date()
            && <span className="statut bg-vert-apave uppercase">maj</span>
        }                        
    </div>;
}