import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';
import FileApi from 'shared/api/File';

const ActionApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("actions/searches", query, from, size, sorting, direction);
    },

    count: function(query) {
        return AbstractApi.count("actions/searches/count", query);
    },

    get: function(id) {
        return AbstractApi.get("actions", id);
    },

    delete: function(id) {
        return AbstractApi.delete("actions", id);
    },
    
    save: function(data) {
        return AbstractApi.save("actions", data);
    },

    uploadAttachments: function(files) {
        return FileApi.upload("actions/upload/attachments", files);
    },

    export: function(query) {
        return AbstractApi.export("actions", query);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            "actions/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },
}
export default ActionApi;