import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';

const PREFIX = "requirementsaffected";

const RequirementAffectedApi = {

    get: function(id) {
        return AbstractApi.get(PREFIX, id);
    },

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search(PREFIX + "/searches", query, from, size, sorting, direction);
    },

    inlineUpdate: function(affectations) {
        return ApiRequest
            .put(
                PREFIX,
                {
                    "affectations": affectations,
                }
            ).then(response => response.data.message);
    },

    save: function(data) {
        return AbstractApi.save(PREFIX, data);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            PREFIX + "/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        ).then(response => response.data.message);
    },

    export: function(query, fileName, sorting = null, direction = null) {
        return AbstractApi.export(PREFIX, query, "", fileName, sorting, direction);
    },

    import: function(file) {
        const formData = new FormData();
        formData.append('file', file);
        return ApiRequest
            .post(
                PREFIX + "/import",
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                }
            )
            .then(response => response.data);
    },

    /* Comments */

    saveComment: function(requirementAffected, data) {
        return AbstractApi.save(PREFIX + "/" + requirementAffected + "/comment", data);
    },

    deleteComment: function(requirementAffected, id) {
        return AbstractApi.delete(PREFIX + "/" + requirementAffected + "/comment", id); 
    },
}
export default RequirementAffectedApi;