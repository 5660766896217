import { useEffect, useState } from 'react';
import HtmlComponent from "shared/components/HtmlComponent";
import FieldComponent from 'shared/components/FieldComponent';
import { canPerform } from "shared/services/Can";
import { formatDate, formatUser } from "shared/services/Utils";
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';

export default function CommentItemComponent(props) {

    const MODE_SHOW = 1;
    const MODE_EDIT = 2;

    const { value, onChange, onRemove, error, watchsite } = props;
    const [account] = useAccount();
    const newComment = (value.id === null);
    const [mode, setMode] = useState(newComment ? MODE_EDIT : MODE_SHOW);
    const [canWrite] = useAccess();

    function edit(event) {
        event.preventDefault();
        setMode(MODE_EDIT);
    }
    
    useEffect(() => {
        if (value.updated_at) {
            setMode(MODE_SHOW);
        }
    }, [value.updated_at])

    function preRemove(event) {
        event.preventDefault();
        event.stopPropagation();
        onRemove(value.id);
    }

    return (
        <tr className={"border-b border-gris-25" + (newComment ? " bg-gris-10" : "")}>
            <td className="w-200px text-right">
                {newComment && <span>Rajouter un commentaire</span>}
                
                {!newComment && (<>
                    {formatDate(value.created_at)}<br />
                    {value.created_by && <strong>{formatUser(value.created_by)}</strong>}
                </>)}
            </td>
            <td>
                {(mode === MODE_SHOW && (
                    <HtmlComponent>{value.comment}</HtmlComponent>
                )) || (mode === MODE_EDIT && (
                    <FieldComponent
                        type="textarea"
                        className="field h-100px"
                        name={"comment[" + value.id + "]"}
                        value={value.comment}
                        onChange={v => onChange(value.id, "comment", v)}
                        wrap={false}
                        rte={true}
                        error={error ? error.comment : null}
                    />
                ))}
            </td>
            {canWrite(watchsite.id) && canPerform('sheetaffected:comment:write', {comment: value, account: account}) && (
                <td className="border-l border-gris-25 w-40px no-padding valign-center">
                    <ul className="actions">
                        {mode === MODE_SHOW && 
                            <li>
                                <button onClick={edit} className="infobulle" data-info="Editer le commentaire">
                                    <i className="icon-actions-modifier" aria-hidden="true"></i>
                                </button>
                            </li>
                        }
                        <li>
                            <button onClick={preRemove} className="infobulle" data-info="Supprimer le commentaire">
                                <i className="icon-boutons-poubelle" aria-hidden="true"></i>
                            </button>
                        </li>
                    </ul>
                </td>
            )}
        </tr>
    )
}