import AbstractApi from 'shared/api/Abstract';

const PreferenceApi = {

    get: function(id) {
        return AbstractApi.get("preferences", id);
    },
    
    save: function(data) {
        return AbstractApi.save("preferences", data, "identifier");
    }
}
export default PreferenceApi;