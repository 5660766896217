const AccountUtils = {

    getCategoryLabel: function(account) {
        return account.category_label ? account.category_label : "Catégorie";
    },
    
    getSubCategoryLabel: function(account) {
        return account.subcategory_label ? account.subcategory_label : "Sous-catégorie";
    },
    
    getCategoriesLabel: function(account) {
        return AccountUtils.getCategoryLabel(account)  + " / " + AccountUtils.getSubCategoryLabel(account);
    },
}

export default AccountUtils;