import _ from 'lodash';
import SheetAffectedApi from 'shared/api/SheetAffected';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function SheetAffectedAutocomplete(criterias, callback, orderingField = "sheet.title", orderingDirection = 'ASC') {

    if (_.isNil(criterias.keyword) || criterias.keyword === "" || criterias.keyword.length < 3) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": []
        }
    };

    if (criterias.keyword) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"match": {"sheet.id": parseInt(criterias.keyword) ? parseInt(criterias.keyword) : 0}},
                    {"wildcard": {"sheet.title": criterias.keyword + "*"}}
                ]
            }
        });
    }

    if(criterias.watchsites) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "must": [
                    {"terms": {"watchsite": criterias.watchsites}}
                ]
            }
        });
    }

    SheetAffectedApi
        .search(queryArgs, 0, 25, orderingField, orderingDirection)
        .then(([sheets]) => {
            callback(
                compileDataToSelectOptions(sheets, 'sheet.id', ['sheet.id', 'sheet.title'])
            );
        });
}

export default _.debounce(SheetAffectedAutocomplete, 300);

export function getSheetAffectedDefaultOptions(values, orderingField = "sheet.title", orderingDirection = 'ASC') {
    return SheetAffectedApi
        .search(
            {"bool": {"must": [{"terms": {"sheet.id": values}}]}},
            0,
            25,
            orderingField,
            orderingDirection
        )
        .then(([sheets]) => compileDataToSelectOptions(sheets, 'sheet.id', ['sheet.id', 'sheet.title']));
}
