export default function ConformityStatisticsChartLegend() {

    return (
        <>
            <ul className="progress-legend">
                <li><span className="bg-state-todecide"></span>Planifié</li>
                <li><span className="bg-state-unnecessary"></span>Réalisé</li>
                <li><span className="bg-state-todo"></span>Evalué</li>
                <li><span className="bg-state-validated"></span>Clôturé</li>
            </ul>
        </>
    );
}


