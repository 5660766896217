import _ from 'lodash';
import RequirementAffectedApi from 'api/RequirementAffected';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function RequirementAffectedAutocomplete(criterias, callback, orderingField = "id", orderingDirection = 'ASC') {

    if (_.isNil(criterias.keyword) || criterias.keyword === "" || criterias.keyword.length < 3) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": []
        }
    };

    if (criterias.keyword) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"match": {"requirement.id": parseInt(criterias.keyword) ? parseInt(criterias.keyword) : 0}},
                ]
            }
        });
    }

    if(criterias.sheets_affected) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "must": [
                    {"terms": {"sheet_affected": criterias.sheets_affected}}
                ]
            }
        });
    }

    if(criterias.sheets) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "must": [
                    {"terms": {"sheet_affected.sheet": criterias.sheets}}
                ]
            }
        });
    }

    RequirementAffectedApi
        .search(queryArgs, 0, 25, orderingField, orderingDirection)
        .then(([requirements]) => {
            callback(
                compileDataToSelectOptions(requirements, 'requirement.id', ['requirement.id'])
            );
        });
}

export default _.debounce(RequirementAffectedAutocomplete, 300);

export function getRequirementAffectedDefaultOptions(values, orderingField = "requirement.id", orderingDirection = 'ASC') {
    return RequirementAffectedApi
        .search(
            {"bool": {"must": [{"terms": {"requirement.id": values}}]}},
            0,
            25,
            orderingField,
            orderingDirection
        )
        .then(([requirements]) => compileDataToSelectOptions(requirements, 'requirement.id', ['requirement.id']));
}
