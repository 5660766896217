import _ from 'lodash';

export function useAccount() {
    let account = {};
    let tempAccount = 0;
    let nbaccounts = 0;

    if (!_.isUndefined(localStorage.getItem("logged-account"))) {
        account = JSON.parse(localStorage.getItem("logged-account"));
    }

    if (!_.isUndefined(localStorage.getItem("temp-account"))) {
        tempAccount = parseInt(localStorage.getItem("temp-account"));
    }

    if (!_.isUndefined(localStorage.getItem("logged-nbaccounts"))) {
        nbaccounts = localStorage.getItem("logged-nbaccounts");
    }

    function setAccount(data) {
        localStorage.setItem("logged-account", JSON.stringify(data));
    }

    function setTempAccount(id) {
        localStorage.setItem("temp-account", parseInt(id));
    }

    function setNbAccounts(data) {
        localStorage.setItem("logged-nbaccounts", data);
    }

    function unsetAccount() {
        localStorage.removeItem("temp-account");
        localStorage.removeItem("logged-account");
        localStorage.removeItem("logged-nbaccounts");
    }

    return [account, nbaccounts, setAccount, setNbAccounts, unsetAccount, tempAccount, setTempAccount];
}