export default function SectionTree(props) {

    const { sections } = props;

    function renderRow(row) {
        return (<>
            {row.parent && (
                renderRow(row.parent)
            )}
            <li>{row.title}</li>
        </>);
    }

    return (
        <ul className="arborescence sectiontree">
            {renderRow(sections)}
        </ul>
    )
}