import { ACTION_CREATE, ACTION_DELETE, ACTION_UPDATE }  from 'components/CommentsComponent';
import _ from 'lodash';

const CommentUtils = {

    doActions: function(commentApi, actions, objectId, comments) {
        return new Promise((resolve, reject) => {
            if (actions.length === 0) {
                return resolve();
            }

            const action = actions[0];
            const commentIndex = comments.findIndex(c => c.id === action.id);
            const comment = comments[commentIndex];

            const actionsFiltered = actions.filter((item, cIndex) => cIndex !== 0);

            switch(action.action) {
                case ACTION_CREATE:
                case ACTION_UPDATE:
                    commentApi
                        .saveComment(objectId, comment)
                        .then(() => resolve(CommentUtils.doActions(commentApi, actionsFiltered, objectId, comments)))
                        .catch((error) => reject({error: error, id: comment.id}));
                    break;
                case ACTION_DELETE:
                    commentApi
                        .deleteComment(objectId, action.id)
                        .then(() => resolve(CommentUtils.doActions(commentApi, actionsFiltered, objectId, comments)))
                        .catch((error) => reject({error: error, id: action.id}));
                    break;
                default: return resolve();
            }
        });
    },

    doMultipleActions: function(commentApi, multipleActions) {
        return new Promise((resolve, reject) => {
            if (_.size(multipleActions) === 0) {
                return resolve();
            }

            const firstKey = _.findKey(multipleActions);
            const multipleAction = multipleActions[firstKey];
            const [objectId, actions, comments] = multipleAction;
            
            delete multipleActions[firstKey];

            CommentUtils.doActions(commentApi, actions, objectId, comments)
                .then(() => resolve(CommentUtils.doMultipleActions(commentApi, multipleActions)))
                .catch((error) => reject(error));
        });
    }
}

export default CommentUtils;