export default function StatisticsChartItemBlock(props) {

    const { title, value, percentage, bgClass  } = props;

    return (
        <>
            {value > 0 &&
                <div
                    className="progress-bar-container"
                    style={{width: `${percentage}%`, display: 'inline-block'}}
                >
                    <div className={`left-progress-bar ${bgClass}`} aria-hidden="true"></div>
                    <div className={`top-progress-bar ${bgClass}`} aria-hidden="true"></div>
                    {percentage > 2 && <div
                        className={`progress-bar ${bgClass}`}
                        role="progressbar"
                        title={`${title}: ${percentage} % - ${value.toLocaleString("fr", {maximumFractionDigits: 2})} fiche(s)`}
                        displayed-value={`${percentage} %`}
                        aria-valuenow={percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>}
                    {percentage <= 2 && <div
                        className={`progress-bar ${bgClass}`}
                        role="progressbar"
                        title={`${title}: ${percentage} % - ${value.toLocaleString("fr", {maximumFractionDigits: 2})} fiche(s)`}
                        aria-valuenow={percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>}
                </div>
            }
        </>
    );
}