import React from 'react';

import TreeNode from 'views/user/form/accounttree/TreeNode';
import TreeWatchsite from 'views/user/form/accounttree/TreeWatchsite';

export default function AccountTree(props) {

    return (           
        <div className="row">
            <div className="col-md-10 offset-md-2">
                <ul className="arborescence accounttree no-arrow offset-350px">
                    {props.tree.nodes && props.tree.nodes.map((treeItem) => (
                        <TreeNode
                            key={treeItem.id}
                            item={treeItem}
                            parentWriteaccess={false}
                            parentReadaccess={false}
                            onWatchsiteReadAccessChange={props.onWatchsiteReadAccessChange}
                            onWatchsiteWriteAccessChange={props.onWatchsiteWriteAccessChange}
                            onNodeReadAccessChange={props.onNodeReadAccessChange}
                            onNodeWriteAccessChange={props.onNodeWriteAccessChange}
                            accesses={props.accesses}
                            isStart={props.isStart}
                        />
                    ))}
                    {props.tree.watchsites && props.tree.watchsites.map((treeItem) => (
                        <TreeWatchsite
                            key={treeItem.id}
                            item={treeItem}
                            parentWriteaccess={false}
                            parentReadaccess={false}
                            onWatchsiteReadAccessChange={props.onWatchsiteReadAccessChange}
                            onWatchsiteWriteAccessChange={props.onWatchsiteWriteAccessChange}
                            onNodeReadAccessChange={props.onNodeReadAccessChange}
                            onNodeWriteAccessChange={props.onNodeWriteAccessChange}
                            accesses={props.accesses}
                            isStart={props.isStart}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}