import React from 'react';
import BarLoader from "react-spinners/BarLoader";
import ConformityStatisticsChartItem from 'views/statistics/list/Conformity/ChartItem';
import ConformityStatisticsChartLegend from 'views/statistics/list/Conformity/ChartLegend';
import ActionPlanStatisticsChartItem from 'views/statistics/list/ActionPlan/ChartItem';
import ActionPlanStatisticsChartLegend from 'views/statistics/list/ActionPlan/ChartLegend';

export default function StatisticsChart(props) {

    const { rows, indicator, loading } = props;

    return (
        <>
            {loading && <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />}
            {!loading && <>
                {indicator === "action-plan-progress" ? (<>
                    {[...rows].map((row) =>
                        <ActionPlanStatisticsChartItem key={`chart-item-${row.group_id}`} row={row} />
                    )}
                    <ActionPlanStatisticsChartLegend />
                </>) : (<>
                    {[...rows].map((row) =>
                        <ConformityStatisticsChartItem key={`chart-item-${row.group_id}`} row={row} />
                    )}
                    <ConformityStatisticsChartLegend />
                </>)}
            </>}
        </>
    );
}