import React from 'react';

export default function WatchsiteAffectedHeader(props) {

    const { columns } = props;

    return (
        <tr>
            <th className="w-34px text-center valign-top"></th>
            {columns.map((column) => {
                if (column.is_active) {
                    return (
                        <th key={column.id}>
                            {column.title}
                        </th>
                    );
                }
                return <React.Fragment key={column.id}></React.Fragment>;
            })}
        </tr>
    );
}