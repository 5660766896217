import { React, Link } from 'commons';
import { nl2br, crop, formatDate, prefixLinkTo } from 'shared/services';
import { HtmlComponent } from 'shared/components';
import DocumentarySpaceResultRowPicto from 'views/documentaryspace/Result/Row/Picto';
import DocumentarySpaceDetailNews from 'views/documentaryspace/Detail/News';

export default function DocumentarySpaceResultRowNews(props) {

    function openDetail(event) {
        event.preventDefault();
        props.setDetail(<DocumentarySpaceDetailNews onRequestClose={() => props.setDetail(null)} document={props.row} />);
    }

    return <li>
        <DocumentarySpaceResultRowPicto row={props.row} />
        <div className="contenu">
            {props.row.image && <div className="image">
                <img src={`data:image/jpeg;base64,${props.row.image.image}`} alt="" />
            </div>}
            <div className="infos">
                <Link onClick={openDetail} to={prefixLinkTo() + `/documentary-space/news/${props.row.id}`}>{props.row.title}</Link>
                <p>{formatDate(props.row.date)}</p>
                {props.classification !== "domain" &&
                    <p>{props.row.domain.value} / {props.row.subdomain.value} / {props.row.theme && <>{props.row.theme.value}</>}</p>}
                {props.row.description && <p>{nl2br(crop(props.row.description, 200))}</p>}
                {props.row.url && <p>
                    <a target="_blank" rel="noreferrer" href={props.row.url}>{props.row.url}</a>
                </p>}
                {props.row.sheet && <><p>
                    <Link to={prefixLinkTo() + `/sheets/${props.row.sheet.id}`}>{props.row.sheet.id}</Link>                    
                </p><HtmlComponent>{props.row.sheet.title}</HtmlComponent></>}
            </div>
        </div>
    </li>;
}