import React from 'react';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

export default function Maintenance() {

    return (
        <>
            <section className="login">
                <div className="form">
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <div
                            className="bg-blanc border-t border-rlb border-gris-40"
                            dangerouslySetInnerHTML={{__html: sessionStorage.getItem("maintenance")}}
                        />
                    </section>
                </div>
                </div>
            </section>
        </>
    );
}
