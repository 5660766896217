import React, { useState } from 'react';
import TreeWatchsite from 'views/user/form/accounttree/TreeWatchsite';

export default function TreeNode(props) {

    const [active, setActive] = useState(true);

    const access = props.accesses.find((item => (item.node && item.node.id === props.item.id)));
    
    let writeAccess = false;
    let readAccess = false;
    if (access) {
        writeAccess = props.parentWriteaccess || access.writeaccess;
        readAccess = !writeAccess && (props.parentReadaccess || access.readaccess);
    } else {
        if (props.parentWriteaccess) {
            writeAccess = props.parentWriteaccess
        }
        if (props.parentReadaccess) {
            readAccess = !writeAccess && props.parentReadaccess;
        }        
    }

    return (
        <li className={active === true ? "actif" : ""}>
            <div className="highlight">
                {(props.item.childs.length > 0 || props.item.watchsites.length > 0) && 
                    <button type="button" className="btn btn-primary icon icon-only" onClick={() => active === true ? setActive(false) : setActive(true)}>
                        <i className={active ? "icon-boutons-moins" : "icon-boutons-plus"}></i>
                    </button>
                }
                <fieldset className="at-left">
                    <legend className="sr-only">Droits d'accès&nbsp;:</legend>
                    <div>
                        <input
                            type="checkbox"
                            value={props.item.id}
                            id={`node-${props.item.id}-read`}
                            onChange={props.onNodeReadAccessChange}
                            disabled={props.parentWriteaccess || props.parentReadaccess}
                            checked={readAccess}
                        />
                        <label htmlFor={`node-${props.item.id}-read`} className="w-100px text-center"><span className="sr-only">Lecture</span></label>
                        <input
                            type="checkbox"
                            value={props.item.id}
                            id={`node-${props.item.id}-write`}
                            onChange={props.onNodeWriteAccessChange}
                            disabled={props.isStart === true || props.parentWriteaccess}
                            checked={props.isStart === false && writeAccess}
                        />
                        <label htmlFor={`node-${props.item.id}-write`} className="w-100px text-center"><span className="sr-only">Écriture</span></label>
                    </div>
                </fieldset>
                {props.item.label}
            </div>       
            {(props.item.childs || props.item.watchsites) && 
                <ul className={active === true ? "" : "d-none"}>
                {props.item.childs.map((child) => (
                    <TreeNode
                        key={child.id}
                        item={child}
                        parent={props.item}
                        parentWriteaccess={writeAccess}
                        parentReadaccess={writeAccess || readAccess}
                        onWatchsiteReadAccessChange={props.onWatchsiteReadAccessChange}
                        onWatchsiteWriteAccessChange={props.onWatchsiteWriteAccessChange}
                        onNodeReadAccessChange={props.onNodeReadAccessChange}
                        onNodeWriteAccessChange={props.onNodeWriteAccessChange}
                        accesses={props.accesses}
                        isStart={props.isStart}
                    />
                ))}
                {props.item.watchsites.map((watchsite) => (
                    <TreeWatchsite
                        key={watchsite.id}
                        item={watchsite}
                        parentWriteaccess={writeAccess}
                        parentReadaccess={writeAccess || readAccess}
                        onWatchsiteReadAccessChange={props.onWatchsiteReadAccessChange}
                        onWatchsiteWriteAccessChange={props.onWatchsiteWriteAccessChange}
                        onNodeReadAccessChange={props.onNodeReadAccessChange}
                        onNodeWriteAccessChange={props.onNodeWriteAccessChange}
                        accesses={props.accesses}
                        isStart={props.isStart}
                    />
                ))}
                </ul>
            }   
        </li>
    )
}