import React, { useState } from 'react';
import Modal from 'react-modal';
import ExpressProcessing from 'partials/ExpressProcessing';
import { mediumModalCustomStyles } from 'shared/services/ModalStyle';
import { useAccount } from 'hooks/Account';
import { canPerform, prefixLinkTo } from "shared/services";

export default function SheetAffectedListBatch(props) {

    const [expressProcessingOpened, setExpressProcessingOpened] = useState(false);
    const [account] = useAccount();
    const hasActionAccess = canPerform("account:actionplan", {account: account});

    function createAction() {
        if (props.selection.length === 0) {
            props.history.push(prefixLinkTo() + '/actions/new');
        } else {
            const sheets = [];
            const watchsites = [];
            const params = {};
    
            props.selection.forEach(item => {
                if (!sheets.includes(item.sheet)) {
                    sheets.push(item.sheet);
                }
                if (!watchsites.includes(item.watchsite)) {
                    watchsites.push(item.watchsite);
                }
            });
    
            if (sheets.length > 0) {
                params.sheets = sheets.join(',');
            }
            if (watchsites.length > 0) {
                params.watchsites = watchsites.join(',');
            }

            props.history.push(prefixLinkTo() + '/actions/new?' + (new URLSearchParams(params)).toString());
        }
    }

    return (
        <>
            <button type="button" onClick={() => setExpressProcessingOpened(true)} className="btn btn-primary h25">Traitement express</button>
            {hasActionAccess && 
                <button type="button" onClick={createAction} className="btn btn-primary h25">
                    <i className="icon-boutons-plus" aria-hidden="true"></i>&nbsp;Créer une action
                </button>
            }
            <Modal isOpen={expressProcessingOpened} onRequestClose={() => setExpressProcessingOpened(false)} style={mediumModalCustomStyles}>
                <ExpressProcessing
                    selection={props.selection}
                    onRequestClose={() => setExpressProcessingOpened(false)}
                    onSuccess={() => {
                        setExpressProcessingOpened(false);
                        props.onSuccess();
                    }}
                />
            </Modal>
        </>
    );
}