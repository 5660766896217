export const ACTION_STATUS_PLANNED = 1;
export const ACTION_STATUS_COMPLETED = 2;
export const ACTION_STATUS_EVALUATED = 3;
export const ACTION_STATUS_CLOSED = 4;
export const ACTION_PRIORITY_NONE = 0;
export const ACTION_PRIORITY_1 = 1;
export const ACTION_PRIORITY_2 = 2;
export const ACTION_PRIORITY_3 = 3;
export const ACTION_PRIORITY_4 = 4;

export const ACTION_STATUS = {
    [ACTION_STATUS_PLANNED]: "Planifié",
    [ACTION_STATUS_COMPLETED]: "Réalisé",
    [ACTION_STATUS_EVALUATED]: "Evalué",
    [ACTION_STATUS_CLOSED]: "Clôturé",
}

export const ACTION_STATUS_COLOR = {
    [ACTION_STATUS_PLANNED]: "error",
    [ACTION_STATUS_COMPLETED]: "new",
    [ACTION_STATUS_EVALUATED]: "alert",
    [ACTION_STATUS_CLOSED]: "valid",
}

export function getActionsStatus() {
    let status = [];
    for (const [key, value] of Object.entries(ACTION_STATUS)) {
        status.push({
            value: parseInt(key), 
            label: value,
            color: ACTION_STATUS_COLOR[key],
        });
    }
    return status;
};

export function getActionStatusLabel(status) {
    return ACTION_STATUS[status];
}

export function getActionStatusColor(status) {
    return ACTION_STATUS_COLOR[status];
}