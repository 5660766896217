import FieldComponent from 'shared/components/FieldComponent';
import { useAccount } from 'hooks/Account';
import AccountUtils from 'shared/services/AccountUtils';

export default function SheetAffectedCategoryPicker(props) {

    const { categories, value, error, onChange } = props;
    const [account] = useAccount();

    function categoriesChange(values) {
        let newValues = [];

        const mapper = (item) => {
            if (item.children) {
                item.children = item.children.map(mapper);
                item.children.forEach(child => {
                    if (!newValues.includes(item.value)
                        && (values.includes(child.value) || newValues.includes(child.value))
                    ) {
                        newValues.push(item.value);
                     }
                });
            }
            if (values.includes(item.value) && !newValues.includes(item.value)) {
                newValues.push(item.value);  
            }

            return item;
        };

        categories.map(mapper);

        onChange(newValues);
    }

    return (
        <FieldComponent 
            type="dropdown-tree-select"
            label={AccountUtils.getCategoriesLabel(account)}
            name="categories"
            data={categories}                     
            mode="hierarchical"
            error={error}
            value={value}
            onChange={categoriesChange}
        />
    );
}