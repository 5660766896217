import ApiRequest from 'shared/services/ApiRequest';

const FavoriteApi = {

    add: function(type, id) {
        return ApiRequest.post("favorites/" + type + "/" + id).then(res => res.data.success);
    },

    remove: function(type, id) {
        return ApiRequest.delete("favorites/" + type + "/" + id).then(res => res.data.success);
    }
}

export default FavoriteApi;