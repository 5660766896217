import { getActionStatusLabel } from 'data/Action';
import { getPriorityLabel } from 'data/Priority';
import { getAffectationStatusLabel, getConformityModeLabel, getConformityLabel } from 'data/SheetAffected';

const account = JSON.parse(localStorage.getItem("logged-account"));

export const actionLabel = {
    "update": "Mise à jour",
    "create": "Création",
};

export const typeLabel = {
    "actions": "action",
    "sheets_affected": "fiche",
    "requirements_affected": "exigence",
    "App\\Entity\\Action": "action",
    "App\\Entity\\SheetAffected": "fiche",
    "App\\Entity\\RequirementAffected": "exigence",
};

export const fieldRenderer = {
    "App\\Entity\\SheetAffected": {
        "hidden": "bool",
        "include_in_alert": "bool",
        "updated_at": "date",
        "created_at": "date",
        "evaluation_date": "date",
        "affectation_date": "date",
        "review_date": "date",
        "equivalent_evaluation_date": "date",
        "equivalent_review_date": "date",
        "conformity_mode": getConformityModeLabel,
        "conformity": getConformityLabel,
        "status": getAffectationStatusLabel,
        "categories": (values) => {
            return values;
        }
    },
    "App\\Entity\\RequirementAffected": {
        "hidden": "bool",
    },
    "App\\Entity\\Action": {
        "hidden": "bool",
        "updated_at": "date",
        "created_at": "date",
        "evaluation_date": "date",
        "closing_date": "date",
        "deadline_date": "date",
        "achievement_date": "date",
        "status": getActionStatusLabel,
        "priority": getPriorityLabel,
        "send_alert": "bool",
    }
};

export const fieldLabel = {
    "App\\Entity\\SheetAffected": {
        "updated_at": "Date de mise à jour",
        "created_at": "Date de création",
        "updated_by": "Auteur de la mise à jour",
        "created_by": "Auteur de la création",
        "sheet": "Fiche",
        "watchsite": "Point de veille",
        "affectation_date": "Date d'affectation",
        "categories": "Catégories",
        "priority": "Priorité",
        "equivalent_priority": "Priorité équivalente",
        "evaluation_date": "Date d'évaluation",
        "equivalent_evaluation_date": "Date d'évaluation équivalente",
        "review_date": "Date de revue",
        "equivalent_review_date": "Date de revue équivalente",
        "conformity_mode": "Mode de conformité",
        "conformity": "Conformité",
        "equivalent_conformity": "Conformité équivalente",
        "include_in_alert": "Inclusion dans l'alerte",
        "hidden": "Masquer",
        "hidden_date": "Date masquage",
        "comment_consultant": "Commentaire consultant",
        "comment_headquarters": "Commentaire siège",
        "pilot": "Pilote",
        "comments": "Commentaire",
        "comment": "Commentaire",
        "responsable_evaluation": "Responsable de l'évaluation",
        "nb_requirements": "Nombre d'exigence",
        "nb_requirements_new": "Nombre d'exigence nouvel",
        "nb_requirements_info": "Nombre d'exigence pour information",
        "nb_requirements_na": "Nombre d'exigence non applicable",
        "nb_requirements_tospecified": "Nombre d'exigence à spécifier",
        "nb_requirements_nok": "Nombre d'exigence en écart",
        "nb_requirements_ok": "Nombre d'exigence conforme",
        "nb_actions": "Nombre d'actions",
        "nb_operative_requirements": "Nombre d'exigence traité ou à traiter",
    },
    "App\\Entity\\RequirementAffected": {
        "updated_at": "Date de mise à jour",
        "created_at": "Date de création",
        "updated_by": "Auteur de la mise à jour",
        "created_by": "Auteur de la création",
        "hidden": "Masquer",
        "priority": "Priorité",
        "conformity": "Conformité",
        "evaluation_date": "Date d'évaluation",
        "review_date": "Date de revue",
        "comments": "Commentaire",
        "comment": "Commentaire"
    },
    "App\\Entity\\Action": {
        "sheets": "Fiches",
        "requirements": "Exigences",
        "updated_at": "Date de mise à jour",
        "created_at": "Date de création",
        "updated_by": "Auteur de la mise à jour",
        "created_by": "Auteur de la création",
        "label": "Libellé de l'action",
        "description": "Action détaillée",
        "cost": "Cout",
        "evaluation_date": "Date d’évaluation",
        "closing_date": "Date de réalisation",
        "deadline_date": "Date limite de réalisation",
        "achievement_date": "Date de réalisation",
        "status": "Statut",
        "priority": "Priorité",
        "grouping_code": "Code de regroupement",
        "send_alert": "Envoyer l'alerte",
        "evaluation_report": "CR d’évaluation",
        "achievement_report": "CR de réalisation",
        "custom_field1": account ? account.customfield1_label : "",
        "custom_field2": account ? account.customfield2_label : "",
        "watchsite": "Point de veille",
        "pilot": "Pilote",
    }
};